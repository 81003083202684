// extracted by mini-css-extract-plugin
export var relativeWrapper = "Footer-module--relativeWrapper--AlMRZ";
export var decor = "Footer-module--decor--3LH5t";
export var hamburger = "Footer-module--hamburger--1djoo";
export var units = "Footer-module--units--1fpU3";
export var reactCalendarMonthViewDays = "Footer-module--react-calendar__month-view__days--Q6Agm";
export var reactCalendarTile = "Footer-module--react-calendar__tile--3W43d";
export var reactCalendarTileActive = "Footer-module--react-calendar__tile--active--smm4H";
export var container = "Footer-module--container--Evt0h";
export var row = "Footer-module--row--1D-jA";
export var lastRow = "Footer-module--lastRow--38aR5";
export var wrapper = "Footer-module--wrapper--12P2S";
export var social = "Footer-module--social--30xC6";
export var logo = "Footer-module--logo--19Tua";
export var contact = "Footer-module--contact--2WS8P";
export var copyright = "Footer-module--copyright--3Tp8b";
export var programs = "Footer-module--programs--2yfSf";