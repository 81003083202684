import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import { BackButton, NextButton } from '@astor/ArrowButtons';
import * as styles from './Amenities.module.scss';

const tabList = [
  'GYM',
  'COWORKING',
  'STORAGE',
  'PARKING',
];

const carouselContent = [
  {
    title: 'Gym',
    content: (
      <p>Astor offers state of the art gym equipment including the brand new MIRROR Technology gym by Lululemon. This technology is provided to our renters with no additional cost. We also host fitness events throughout the year, including yoga, kickboxing and more.</p>
    ),
    image: (
      <StaticImage
        src="../../../images/astor/Amenities/gym.jpeg"
        className={styles.image}
        alt="girls working out"
      />
    ),
  },
  {
    title: 'Coworking',
    content: (
      <p>Our thoughtful coworking spaces are designed with the aesthetics of work-from-home convenience and office productivity.</p>
    ),
    image: (
      <StaticImage
        src="../../../images/astor/Amenities/coworking.jpeg"
        className={styles.image}
        alt="coworking room"
        formats={["auto"]}
        quality={100}
      />
    ),
  },
  {
    title: 'Storage',
    content: (
      <p>Astor offers residents secure, private storage right on the property for added convenience. With easy access and strong security, your belongings are kept safe and nearby.</p>
    ),
    image: (
      <StaticImage
        src="../../../images/astor/Amenities/storage.jpeg"
        className={styles.image}
        alt="storage facility"
      />
    ),
  },
  {
    title: 'Parking',
    content: (
      <p>- Indoor Parking Spaces<br/>- Electric Vehicle Charging Capabilities</p>
    ),
    image: (
      <StaticImage
        src="../../../images/astor/Amenities/evcharging.jpeg"
        className={styles.image}
        alt="eletric car charging"
      />
    ),
  },
];

function Amenities() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [cardLeftPos, setCardLeftPos] = useState(0);

  const calculateLeft = (index = selectedTab) => {
    if (index === 0) {
      return setCardLeftPos(window.innerWidth < 1066 ? 0 : 113);
    }
    const width =
      window.innerWidth < 1214 ? window.innerWidth : window.innerWidth;
    const card = document.getElementsByClassName(styles.card)[0];
    const cardWidth = card.scrollWidth;
    const left = (width - cardWidth) / 2 - (cardWidth + 45) * index;

    setCardLeftPos(left);
  };

  const handleSelection = e => {
    const index = parseInt(e.currentTarget.dataset.index);

    setSelectedTab(index);
    calculateLeft(index);
  };

  const goToTab = tabIndex => {
    const newIndex =
      tabIndex < 0 ? tabList.length - 1 : tabIndex % tabList.length;

    setSelectedTab(newIndex);
    calculateLeft(newIndex);
  };

  useEffect(() => {
    calculateLeft();
  }, []);

  return (
    <div className={classNames(styles.relativeWrapper, styles.dark)} style={{ padding: 0 }}>
      <div className={styles.container} id="amenities">
        <div className={styles.textContainer}>
          <h3>Amenities at our community properties</h3>
          <h2>
            Live insouciantly
          </h2>
          <p>Indulge and imbibe, refresh and rejuvenate, gratify and aggrandize your living.
          </p>
        </div>

        <div className={styles.carouselContainer}>
          <div className={styles.tabs}>
            {tabList.map((tabTitle, index) => (
              <div
                key={index}
                onClick={handleSelection}
                className={classNames(styles.tab, {
                  [styles.selected]: index === selectedTab,
                })}
                data-index={index}
              >
                <div className={styles.backgroundImage} />
                <div className={styles.foregroundImage} />
                <span>{tabTitle}</span>
              </div>
            ))}
          </div>

          <div className={styles.mobileTabs}>
            <p>AMENITIES</p>
            <div className={styles.decorLine} />
          </div>

          <div className={styles.carousel}>
            {
              selectedTab > 0 &&
              <BackButton
                page={selectedTab}
                pageCount={tabList.length}
                onClick={() => goToTab(selectedTab - 1)}
                className={classNames(styles.arrow, styles.backButton)}
                small
              />
            }
            {
              selectedTab < tabList.length - 1 &&
              <NextButton
                page={selectedTab}
                pageCount={tabList.length}
                onClick={() => goToTab(selectedTab + 1)}
                className={classNames(styles.arrow, styles.nextButton)}
                small
              />
            }
            <div
              className={styles.cardsWrapper}
              style={{ left: `${cardLeftPos}px` }}
            >
              {carouselContent.map((data, index) => (
                <div key={index} className={styles.card}>
                  {data.image}
                  <div className={styles.info}>
                    <div className={styles.content}>
                      <span>{data.title}</span>
                      <div className={styles.divider} />
                      {data.content}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <StaticImage
        className={styles.decor}
        style={{ bottom: '-800px', right: '64%', width: '1126px' }}
        src="../../../images/arbol/landing-page/decor8.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
      <StaticImage
        className={styles.decor}
        style={{ bottom: '-800px', left: '64%', width: '1126px' }}
        src="../../../images/arbol/landing-page/decor9.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      /> */}
    </div>
  );
}

export default Amenities;
