// extracted by mini-css-extract-plugin
export var relativeWrapper = "Amenities-module--relativeWrapper--2AoLc";
export var decor = "Amenities-module--decor--1fyV2";
export var hamburger = "Amenities-module--hamburger--3mCPX";
export var units = "Amenities-module--units--3l2Sk";
export var reactCalendarMonthViewDays = "Amenities-module--react-calendar__month-view__days--P0CEY";
export var reactCalendarTile = "Amenities-module--react-calendar__tile--3LZNw";
export var reactCalendarTileActive = "Amenities-module--react-calendar__tile--active--sNCDT";
export var container = "Amenities-module--container--3qqx-";
export var textContainer = "Amenities-module--textContainer--2A1fC";
export var carouselContainer = "Amenities-module--carouselContainer--1HtIw";
export var mobileTabs = "Amenities-module--mobileTabs--GjzCL";
export var decorLine = "Amenities-module--decorLine--1WKfX";
export var tabs = "Amenities-module--tabs--_xa97";
export var tab = "Amenities-module--tab--3RTBL";
export var selected = "Amenities-module--selected--2TZ5k";
export var backgroundImage = "Amenities-module--backgroundImage--1XsFB";
export var foregroundImage = "Amenities-module--foregroundImage--7nNU6";
export var carousel = "Amenities-module--carousel--2fv0N";
export var arrow = "Amenities-module--arrow--2b0X9";
export var backButton = "Amenities-module--backButton--2-Rmv";
export var nextButton = "Amenities-module--nextButton--39VCN";
export var cardsWrapper = "Amenities-module--cardsWrapper--33FFP";
export var card = "Amenities-module--card--105eL";
export var image = "Amenities-module--image--1bYRa";
export var info = "Amenities-module--info--1NFYz";
export var divider = "Amenities-module--divider--pB15i";
export var content = "Amenities-module--content--3gtvx";
export var microLogo = "Amenities-module--microLogo--1tm86";