import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import * as styles from './TheNeighborhood.module.scss';
import { BackButton, NextButton } from '@astor/ArrowButtons';
import { usePreviewDocument } from '@utils/usePreviewDocument';

function TheNeighborhood() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [cardLeftPos, setCardLeftPos] = useState({ info: 0, image: 0 });
  const data = useStaticQuery(graphql`
    query astorNeighborhoodPlaces {
      places: allPrismicGenericNeighborhoodPlace(sort: { fields: last_publication_date, order: DESC }) {
        nodes {
          data {
            address
            description
            headline
            name
            directions_link {
              url
              target
            }
            image {
              url
            }
          }
        }
      }
    }
  `);

  const previewedCard = usePreviewDocument('neighborhood_place')?.data;

  const cardList = previewedCard 
                 ? [ previewedCard ]
                 : data.places.nodes.map(e => e.data);

  const calculateLeft = (index = selectedIndex) => {
    const image = document.getElementsByClassName(styles.image)[0];
    const info = document.getElementsByClassName(styles.info)[0];
    const imageLeft = -1 * image?.scrollWidth * index;
    const infoLeft = -1 * info?.scrollWidth * index;

    setCardLeftPos({
      info: infoLeft,
      image: imageLeft
    });
  };

  useEffect(() => {
    calculateLeft();
  }, []);

  const cardCount = cardList.length;

  const handleNext = () => {
    const newIndex = (selectedIndex + 1) % cardCount;
    setSelectedIndex(newIndex);
    calculateLeft(newIndex);
  };

  const handleBack = () => {
    const newIndex = selectedIndex === 0 ? cardCount - 1 : selectedIndex - 1;
    setSelectedIndex(newIndex);
    calculateLeft(newIndex);
  };


  return (
    <div className={styles.relativeWrapper} style={{ padding: 0 }}>
      <div className={styles.container} id="neighborhood">
        <span>THE NEIGHBORHOOD</span>
        <h2>The Best of Newark</h2>
        <div className={styles.carousel}>
          <div className={styles.cardCarousel}>
            <div className={styles.cardWrapper} style={{ left: `${cardLeftPos.image}px` }}>
              {
                cardList.map((node, index) => (
                  <img
                    key={index}
                    src={node.image.url}
                    placeholder="None"
                    className={styles.image}
                    alt={node.headline}
                  />
                ))
              }
            </div>
          </div>
          <div className={styles.infoBlock}>
            {
              selectedIndex > 0 &&
              <BackButton
                page={selectedIndex}
                pageCount={cardCount}
                onClick={handleBack}
                className={classNames(styles.arrow, styles.backButton)}
                small
              />
            }
            {
              selectedIndex < cardCount - 1 &&
              <NextButton
                page={selectedIndex}
                pageCount={cardCount}
                onClick={handleNext}
                className={classNames(styles.arrow, styles.nextButton)}
                small
              />
            }
            <div className={styles.infoCarousel}>
              <div className={styles.infoWrapper} style={{ left: `${cardLeftPos.info}px` }}>
                {
                  cardList.map((node, index) => (
                    <div key={index} className={styles.info}>
                      <h2>{node.headline}</h2>
                      <p className={styles.name}>{node.name}</p>
                      <div className={styles.divider} />
                      <p className={styles.description}>{node.description}</p>
                      <div className={styles.divider} />
                      <p className={styles.address}>{node.address}</p>
                      <a
                        className={styles.directions}
                        href={node.directions_link.url}
                        target={node.directions_link.target}
                        rel="noreferrer"
                      >
                        DIRECTIONS
                      </a>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TheNeighborhood;
