// extracted by mini-css-extract-plugin
export var relativeWrapper = "News-module--relativeWrapper--1NA1E";
export var decor = "News-module--decor--1WgxX";
export var hamburger = "News-module--hamburger--2osfn";
export var units = "News-module--units--35fC5";
export var reactCalendarMonthViewDays = "News-module--react-calendar__month-view__days--2ZjCH";
export var reactCalendarTile = "News-module--react-calendar__tile--2T41K";
export var reactCalendarTileActive = "News-module--react-calendar__tile--active--3WC_Y";
export var container = "News-module--container--3j8KK";
export var newsSources = "News-module--newsSources--2-v1Z";
export var logoList = "News-module--logoList--2Ocau";
export var logo = "News-module--logo--mK666";
export var articleQuote = "News-module--articleQuote--2IgmR";
export var tag = "News-module--tag--2jyi3";
export var link = "News-module--link--fGlw0";