// extracted by mini-css-extract-plugin
export var relativeWrapper = "ArrowButton-module--relativeWrapper--1yTbb";
export var decor = "ArrowButton-module--decor--3qbQv";
export var hamburger = "ArrowButton-module--hamburger--1zpRl";
export var units = "ArrowButton-module--units--120w-";
export var reactCalendarMonthViewDays = "ArrowButton-module--react-calendar__month-view__days--O7ihy";
export var reactCalendarTile = "ArrowButton-module--react-calendar__tile--2hpNx";
export var reactCalendarTileActive = "ArrowButton-module--react-calendar__tile--active--1dqIN";
export var nextButton = "ArrowButton-module--nextButton--1rLBc";
export var small = "ArrowButton-module--small--2s0O5";
export var content = "ArrowButton-module--content--1yXqF";
export var arrow = "ArrowButton-module--arrow--22sVM";
export var position = "ArrowButton-module--position--2K4uH";
export var backButton = "ArrowButton-module--backButton--3T2HP";