// extracted by mini-css-extract-plugin
export var relativeWrapper = "TheNeighborhood-module--relativeWrapper--1DRQT";
export var decor = "TheNeighborhood-module--decor--3f9R6";
export var hamburger = "TheNeighborhood-module--hamburger--l6vs5";
export var units = "TheNeighborhood-module--units--i2vac";
export var reactCalendarMonthViewDays = "TheNeighborhood-module--react-calendar__month-view__days--2zmu0";
export var reactCalendarTile = "TheNeighborhood-module--react-calendar__tile--24IGF";
export var reactCalendarTileActive = "TheNeighborhood-module--react-calendar__tile--active--4iNE_";
export var container = "TheNeighborhood-module--container--1i9Ox";
export var carousel = "TheNeighborhood-module--carousel--2ahEy";
export var cardCarousel = "TheNeighborhood-module--cardCarousel--17QeB";
export var cardWrapper = "TheNeighborhood-module--cardWrapper--3F6h4";
export var image = "TheNeighborhood-module--image--1L5Ag";
export var infoBlock = "TheNeighborhood-module--infoBlock--2JuZV";
export var arrow = "TheNeighborhood-module--arrow--28mpD";
export var backButton = "TheNeighborhood-module--backButton--223-s";
export var nextButton = "TheNeighborhood-module--nextButton--1an6V";
export var infoCarousel = "TheNeighborhood-module--infoCarousel--2uby-";
export var infoWrapper = "TheNeighborhood-module--infoWrapper--2w8l2";
export var info = "TheNeighborhood-module--info--3hNmK";
export var name = "TheNeighborhood-module--name--29Dzo";
export var description = "TheNeighborhood-module--description--oZsZg";
export var address = "TheNeighborhood-module--address--2bUyt";
export var divider = "TheNeighborhood-module--divider--1GhaO";
export var directions = "TheNeighborhood-module--directions--1Adr1";