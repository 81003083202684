import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import 'animate.css';

import LogoSmall from '@images/astor/logo-block.svg';
import LogoTiny from '@images/astor/logo-tiny.svg';
import PhoneIcon from '@images/arbol/header/phone-icon.svg';
import MailIcon from '@images/arbol/header/mail-icon.svg';
import GomesLogo from "@images/home/full-logo.svg";

import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import BaseHeader from '@components/Header';
import * as styles from './styles.module.scss';

const getHref = anchor => {
  const path = typeof window === 'undefined' ? '' : location.pathname;

  if (path === '/property/astor') return anchor;

  if (anchor === '#contact') return anchor;

  const unitComponents = ['#news', '#amenities', '#neighborhood'];
  if (
    /\/property\/astor\/[0-9]+\/?$/.test(path) &&
    unitComponents.includes(anchor)
  )
    return anchor;

  return `/property/astor/${anchor}`;
};
const Header = props => {
  return (
    <BaseHeader
      styles={styles}
      transparent={props.transparent}
      headerContent={header => (
        <>
          <div className={classNames(styles.leftContainer, props?.availability ?  styles.showAvailability : styles.noAvailability)}>
            <a href="https://84-86-astor-street-rentcafewebsite.securecafe.com/onlineleasing/84-86-astor-street/floorplans.aspx" className={classNames(styles.button)}>
              AVAILABILITY
            </a>
          </div>
          <div className={classNames(styles.logoContainer)}>
            <Link to="/property/astor">
              {props.transparent == true &&
              !['mini', 'hidden'].includes(header.state) ? (
                <><LogoTiny className={classNames(styles.logoTiny, styles.mobile)} /><LogoSmall className={classNames(styles.logoSmall, styles.desktop)} /></>
              ) : (
                <LogoTiny className={styles.logoTiny} />
              )}
            </Link>
          </div>
          <div className={classNames(styles.contactContainer, styles.lg)}>
            <a href="tel:9736042862">973 604 2862</a>
            <a href="mailto:astor@bygomes.com">ASTOR@BYGOMES.COM</a>
          </div>
          <div className={classNames(styles.contactContainer, styles.sm)}>
            <div className={styles.iconContainer}>
              <a href="tel:9736042862">
                <PhoneIcon />
              </a>
              <a href="mailto:astor@bygomes.com">
                <MailIcon />
              </a>
            </div>
          </div>
        </>
      )}
      expandedContent={handleClose => (
        <>
          <div className={styles.menuList}>
            <a
              href="https://84-86-astor-street-rentcafewebsite.securecafe.com/onlineleasing/84-86-astor-street/floorplans.aspx"
              onClick={handleClose}
              className={classNames(styles.button, styles.mobile)}
            >
              AVAILABILITY
            </a>
            <ul>
              <li>
                <Link
                  onClick={handleClose}
                  className="link animate__animated"
                  to="https://84-86-astor-street-rentcafewebsite.securecafe.com/onlineleasing/84-86-astor-street/floorplans.aspx"
                >
                  Units
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClose}
                  className="link animate__animated"
                  to={getHref('#amenities')}
                >
                  Amenities
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClose}
                  className="link animate__animated"
                  to={getHref('#neighborhood')}
                >
                  Neighborhood
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClose}
                  className="link animate__animated"
                  to={getHref('#news')}
                >
                  News
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClose}
                  className="link animate__animated"
                  to={getHref('#contact')}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <a
                  onClick={handleClose}
                  className="link animate__animated"
                  href="https://astor-by-gomes-rentcafewebsite.securecafenet.com/residentservices/astor-by-gomes/userlogin"
                >
                  Residents
                </a>
              </li>
            </ul>
            <div className={styles.gomesLogo}>
              <Link to="/"><GomesLogo /></Link>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <StaticImage
              className={styles.image}
              src="../../../images/astor/landing-page/menu.jpg"
              alt="Group eating on rooftop"
            />
          </div>
        </>
      )}
    />
  );
};

Header.propTypes = {
  transparent: PropTypes.bool.isRequired,
  availability: PropTypes.bool,
};

Header.defaultProps = {
  transparent: true,
  availability: false,
};

export default Header;
