import React from 'react';
import Logo from '@images/astor/logo-block.svg';
import AccessibilityIcon from '@images/astor/Footer/accessibility.svg';
import EqualityIcon from '@images/astor/Footer/equality.svg';
import classNames from 'classnames';
import * as styles from './Footer.module.scss';

function Footer() {
  const year = new Date().getFullYear();
  return (
    <div className={styles.container} style={{ marginTop: '104px' }}>
      <div className={styles.row}>
        <div className={styles.wrapper}>
        </div>
        <div className={classNames(styles.wrapper, styles.logo)}>
          <div className={styles.logo}>
            <Logo />
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.contact}>
            <a href="tel:9736042862">973 604 2862</a>
            <a href="mailto:astor@bygomes.com">ASTOR@BYGOMES.COM</a>
          </div>
        </div>
      </div>
      <div className={classNames(styles.row, styles.lastRow)}>
        <div className={styles.wrapper} />
        <div className={styles.wrapper}>
          <p className={styles.copyright}>Copyright © {year} Astor by Gomes. All right reserved.</p>
        </div>
        <div className={classNames(styles.wrapper, styles.programs)}>
          <EqualityIcon />
          <AccessibilityIcon />
        </div>
      </div>
    </div>
  );
}

export default Footer;
